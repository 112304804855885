export const testnet2 = {
  btcConfig: {
    symbol: 'BTC',
    address: '',
    stake_address: '0xe038434d37916f50bb93a3da47d484032b720997',
    precision: 18,
    native_precision: 8
  },
  tokenList: {
    XBTC: {
      symbol: 'XBTC',
      address: '0xadeb1300e4860089d93233dded31b33206ba8432',
      stake_address: '0xe038434d37916f50bb93a3da47d484032b720997',
      precision: 18,
      native_precision: 8
    },
    XSAT: {
      symbol: 'XSAT',
      address: '0x8266f2fbc720012e5Ac038aD3dbb29d2d613c459',
      stake_address: '',
      precision: 18,
      native_precision: 8
    }
  },
  rewardHelperAddr: '0x35B84b7E257749B33d18643f935d54041105D938',
  contractUrl: 'https://chain2.exactsat.io',
  chainHistoryUrl: 'https://exsat2.greymass.com/',
  chainConfig: {
    id: 840000,
    name: 'exsat',
    nativeCurrency: { name: 'BTC', symbol: 'BTC', decimals: 18 },
    rpcUrls: {
      default: { http: ['https://evm2.exactsat.io/'] }
    },
    blockExplorers: {
      default: { name: 'exsat explorer', url: 'https://scan2.exactsat.io/' }
    },
    contracts: {
      /*
       ensRegistry: {
       address: '0x00000000000C2E074eC69A0dFb2997BA6C7d2e1e',
       },
       ensUniversalResolver: {
       address: '0xE4Acdd618deED4e6d2f03b9bf62dc6118FC9A4da',
       blockCreated: 16773775,
       },
       multicall3: {
       address: '0xca11bde05977b3631167028862be2a173976ca11',
       blockCreated: 14353601,
       },
       */
    }
  },
  apiBase: 'https://api2.exactsat.io',
  links: {
    explorer: 'https://scan2.exactsat.io',
    faucet: '',
    docs: 'https://docs.exsat.network/'
  },
  validatorListUrl:
    'https://raw.githubusercontent.com/exsat-network/configurations/main/src/testnet2-validators.json',
  synchronizerListUrl:
    'https://raw.githubusercontent.com/exsat-network/configurations/main/src/testnet2-synchronizers.json',
  cactusHelperAddr: 'https://exsat.network/rpc/bridge-testnet2',
  cactusBridgePermissionId: '0',
  donationDest: '0x3231F3d06F2Be5D26B7a5624DF4153A49D9daD60', // test!!!! remember to change it in PROD !!!!
  mempoolUrl: 'https://mempool.space/testnet/',
  enableBridgeWithdraw: true,
  cbridge_url: 'https://tbrdg.exactsat.io/'
}

export const testnet3 = {
  btcConfig: {
    symbol: 'BTC',
    address: '',
    stake_address: '0xe038434d37916f50bb93a3da47d484032b720997',
    precision: 18,
    native_precision: 8
  },
  tokenList: {
    XBTC: {
      symbol: 'XBTC',
      address: '0x4aa4365da82ACD46e378A6f3c92a863f3e763d34',
      stake_address: '0xe038434D37916F50bb93a3da47D484032b720997',
      precision: 18,
      native_precision: 8
    },
    XSAT: {
      symbol: 'XSAT',
      address: '0x8266f2fbc720012e5Ac038aD3dbb29d2d613c459',
      stake_address: '',
      precision: 18,
      native_precision: 8
    }
  },
  rewardHelperAddr: '0x35B84b7E257749B33d18643f935d54041105D938',
  contractUrl: 'https://chain-tst3.exactsat.io/',
  chainHistoryUrl: 'https://exsat3.greymass.com/',
  chainConfig: {
    id: 839999,
    name: 'exSat Testnet',
    nativeCurrency: { name: 'BTC', symbol: 'BTC', decimals: 18 },
    rpcUrls: {
      default: { http: ['https://evm-tst3.exsat.network/'] }
    },
    blockExplorers: {
      default: { name: 'exsat explorer', url: 'https://scan-testnet.exsat.network/' }
    },
    contracts: {
      /*
       ensRegistry: {
       address: '0x00000000000C2E074eC69A0dFb2997BA6C7d2e1e',
       },
       ensUniversalResolver: {
       address: '0xE4Acdd618deED4e6d2f03b9bf62dc6118FC9A4da',
       blockCreated: 16773775,
       },
       multicall3: {
       address: '0xca11bde05977b3631167028862be2a173976ca11',
       blockCreated: 14353601,
       },
       */
    }
  },
  apiBase: 'https://api3.exsat.network',
  links: {
    explorer: 'https://scan-testnet.exsat.network',
    faucet: 'https://faucet.exsat.network',
    docs: 'https://docs.exsat.network/'
  },
  validatorListUrl:
    'https://raw.githubusercontent.com/exsat-network/configurations/refs/heads/main/src/testnet-validators.json',
  synchronizerListUrl:
    'https://raw.githubusercontent.com/exsat-network/configurations/refs/heads/main/src/testnet-synchronizers.json',
  cactusHelperAddr: 'https://exsat.network/rpc/bridge-testnet3',
  cactusBridgePermissionId: '0',
  donationDest: '0x3231F3d06F2Be5D26B7a5624DF4153A49D9daD60', // test!!!! remember to change it in PROD !!!!
  mempoolUrl: 'https://mempool.space/testnet/',
  enableBridgeWithdraw: false,
  cbridge_url: 'https://t3brdg.exactsat.io/'
}

export const mainnet = {
  btcConfig: {
    symbol: 'BTC',
    address: '',
    stake_address: '0xe038434d37916f50bb93a3da47d484032b720997',
    precision: 18,
    native_precision: 8
  },
  tokenList: {
    XBTC: {
      symbol: 'XBTC',
      address: '0x4aa4365da82ACD46e378A6f3c92a863f3e763d34',
      stake_address: '0xe038434d37916f50bb93a3da47d484032b720997',
      precision: 18,
      native_precision: 8
    },
    XSAT: {
      symbol: 'XSAT',
      address: '0x8266f2fbc720012e5Ac038aD3dbb29d2d613c459',
      stake_address: '',
      precision: 18,
      native_precision: 8
    }
  },
  rewardHelperAddr: '0x35B84b7E257749B33d18643f935d54041105D938',
  contractUrl: 'https://eos.greymass.com/',
  chainHistoryUrl: 'https://eos.greymass.com/',
  chainConfig: {
    id: 7200,
    name: 'exSat Mainnet',
    nativeCurrency: { name: 'BTC', symbol: 'BTC', decimals: 18 },
    rpcUrls: {
      default: { http: ['https://evm.exsat.network/'] }
    },
    blockExplorers: {
      default: { name: 'exSat explorer', url: 'https://scan.exsat.network/' }
    },
    contracts: {
      /*
       ensRegistry: {
       address: '0x00000000000C2E074eC69A0dFb2997BA6C7d2e1e',
       },
       ensUniversalResolver: {
       address: '0xE4Acdd618deED4e6d2f03b9bf62dc6118FC9A4da',
       blockCreated: 16773775,
       },
       multicall3: {
       address: '0xca11bde05977b3631167028862be2a173976ca11',
       blockCreated: 14353601,
       },
       */
    }
  },
  apiBase: 'https://api.exsat.network', //还没部署
  links: {
    explorer: 'https://scan.exsat.network',
    faucet: '',
    docs: 'https://docs.exsat.network/'
  },
  validatorListUrl:
    'https://raw.githubusercontent.com/exsat-network/configurations/refs/heads/main/src/mainnet-validators.json',
  synchronizerListUrl:
    'https://raw.githubusercontent.com/exsat-network/configurations/refs/heads/main/src/mainnet-synchronizers.json',
  cactusHelperAddr: 'https://exsat.network/rpc/bridge',
  cactusBridgePermissionId: '1',
  donationDest: '0x0db1c3692b775FE82444baA3BFB837F65fe51B2d',
  mempoolUrl: 'https://mempool.space/',
  enableBridgeWithdraw: false,
  cbridge_url: 'https://t3brdg.exactsat.io/'
}

<template>
  <nav class="header">
    <div class="header-logo header-col">
      <div class="network">
        <dropdown
          class="net-dropdown"
          inline
          v-model="net"
          @change="onNetChange"
          v-if="nets.length > 1"
          :dropdown-items="nets"
        >
          <div style="white-space: nowrap">
            {{ nets.find((i) => i.value === net)?.text || net }}
          </div>
          <template #dropdown-item="{ item }">
            <div style="white-space: nowrap">{{ item.text }}</div>
          </template>
        </dropdown>
        <div style="padding: 10px" v-else>{{ nets.find((i) => i.value === net)?.text || net }}</div>
      </div>
    </div>
    <div class="right-buttons header-col" v-if="!$route.meta.hideNavButtons">
      <a-flex align="center" justify="end" style="gap: 12px">
        <router-link to="/my-staking" class="btn btn-secondary" v-if="account">
          My Staking
        </router-link>
        <wallet :show="walletShown" />
      </a-flex>
    </div>
  </nav>
</template>

<script setup>
import Dropdown from '@exsat/common/components/Dropdown.vue'
import Wallet from '@/components/Wallet.vue'
import { inject, ref } from 'vue'

const account = inject('account')


const nets = [
  { value: 'mainnet', text: 'Mainnet' },
  { value: 'testnet3', text: 'Testnet: Hayek' },
  { value: 'testnet2', text: 'testnet2', env: 'test' },
]

const net = ref(localStorage.exsatNetwork || 'mainnet')
if (!nets.find((i) => i.value === net.value)) {
  net.value = nets[0].value
}

const walletShown = ref(false)

function onNetChange(val) {
  localStorage.exsatNetwork = val
  location.href = '/'
}
</script>

<style lang="scss" scoped>
.header {
  --nav-bg-color: #1f1f1f;
  --nav-color: #fff;
  position: sticky;
  z-index: 20;
  background-color: var(--nav-bg-color);
  color: #fff;
  top: 0;
  padding: 10px var(--spacing-xl);
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;

  @include sm {
    font-size: var(--font-md);
  }
}

.header-col {
  flex: 1;
  padding-top: 5px;
  padding-bottom: 5px;
}

.header-center {
  flex: 2;
  text-align: center;
  @include sm {
    display: none;
  }
}

.menu-btn {
  img {
    width: 24px;
    height: 24px;
  }

  @include sm-up {
    display: none;
  }
}

.header-logo {
  flex: auto;
}

:deep(.dropdown-menu) {
  background-color: var(--nav-bg-color);
  color: var(--nav-color);
}

.network {
  display: inline-block;
}

.logo {
  height: 60px;
  display: block;

  @include sm {
    height: 30px;
    min-width: 88px;
  }
}

.top-menu {
  position: relative;
  user-select: none;
  text-align: center;
  align-content: center;
  height: 56px;
  border: 1px solid var(--border-gray);
  background-color: var(--bg-color);
  border-radius: var(--radius-2xl);
  color: var(--text-gray);
  display: inline-flex;
  flex-direction: row;

  .arrow-icon {
    width: 16px;
    height: 16px;
  }

  .menu-title {
    white-space: nowrap;
    padding: 12px 20px;
    height: 100%;
    font-size: 14px;
    cursor: pointer;
    transition: color 0.3s;
    line-height: 32px;
    display: inline-flex;
    align-items: center;

    &:hover {
      color: var(--text-body);
    }

    .arrow-icon {
      display: block;
      transition: transform 0.3s;
      transition-timing-function: ease-in-out; // 两个状态动效不一样
    }
  }

  .menu-card {
    position: absolute;
    top: 100%;
    margin-top: 10px;
    overflow: hidden;
    background: var(--bg-color);
    border: solid 1px var(--border-gray);
    border-radius: var(--radius-2xl);
    padding: var(--spacing-md);
    text-align: left;
    white-space: nowrap;

    .menu-detail {
      text-decoration: none;
      position: relative;
      display: flex;
      align-items: center;
      cursor: pointer;
      padding: var(--spacing-lg);
      min-width: 12em;
      border-radius: var(--radius-xl);

      &:hover {
        opacity: 1;
      }

      .menu-icon {
        border-radius: var(--radius-sm);
        overflow: hidden;
        background-color: #242424;
        transition: opacity 0.3s;
        position: relative;
        min-width: 40px;

        img {
          display: block;
          width: 100%;
        }

        &:after {
          content: '';
          display: block;
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background-image: radial-gradient(
            161.14% 105.56% at 50% 0%,
            rgba(0, 0, 0, 0) 32.17%,
            rgba(255, 153, 0, 0.6) 74.33%,
            #ffffff 100%
          );
          opacity: 0;
          pointer-events: none;
          transition: opacity 0.3s;
        }
      }

      .arrow {
        transition: 0.3s;
        opacity: 0;
      }

      &:last-child {
        margin-bottom: 0;
      }

      &:hover .menu-icon:after {
        opacity: 1;
      }

      &:hover,
      &:focus {
        background-color: var(--bg-secondary-color);

        .arrow {
          opacity: 1;
        }

        .badge-container {
          opacity: 1;

          .detail-title {
            opacity: 0;
          }
        }
      }
    }

    .detail-content {
      padding: 0 var(--spacing-xl);
      color: var(--text-gray);

      .detail-title {
        color: var(--text-body);
        transition: opacity 0.3s;
      }

      .detail-context {
        font-size: var(--font-sm);
      }
    }

    .badge-container {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      display: flex;
      flex-direction: row;
      padding: 10px;
      align-items: center;
      font-size: var(--font-sm);
      padding-left: 64px;
      opacity: 0;
      transition: 0.3s;
    }
  }

  .active {
    color: var(--text-body);

    .arrow-icon {
      transform: rotate(180deg);
      transition-timing-function: cubic-bezier(0, 1.31, 0.79, 1.12); // 两个状态动效不一样
    }
  }
}

.slide-left-enter-active,
.slide-left-leave-active {
  transition: 0.3s;
}

.slide-left-enter-from,
.slide-left-leave-to {
  opacity: 0;
  transform: translateX(100%);
}

.fade-down-enter-active,
.fade-down-leave-active {
  transition: 0.3s;
}

.fade-down-enter-from,
.fade-down-leave-to {
  opacity: 0;
  transform: translateY(-10%);
}
</style>

<template>
  <div class="wallet-info">
    <button class="btn btn-secondary connect-wallet" v-if="!account" @click="clickConnect()">
      Connect Wallet
    </button>

    <div class="account" v-else @click.stop="toggleWallet">
      <span class="account-hash">{{ account.slice(0, 5) + '...' + account.slice(-4) }}</span>
      <div class="avatar">
        <svg
          width="48"
          height="48"
          viewBox="0 0 48 48"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect width="48" height="48" rx="24" fill="url(#paint0_radial_2489_1421)" />
          <defs>
            <radialGradient
              id="paint0_radial_2489_1421"
              cx="0"
              cy="0"
              r="1"
              gradientUnits="userSpaceOnUse"
              gradientTransform="translate(-20.5 5.5) rotate(30.7544) scale(70.4006 99.518)"
            >
              <stop offset="0" stop-color="#321E00" />
              <stop offset="0.67" stop-color="#FF9900" />
              <stop offset="1" stop-color="white" />
            </radialGradient>
          </defs>
        </svg>
      </div>
    </div>
  </div>

  <transition name="fade-down">
    <div class="wallet" ref="wallet" v-if="shown">
      <div class="wallet-inner">
        <main>
          <section class="tokens">
            <div class="token" v-for="i in currencies" :key="i">
              <div class="icon token-icon">
                <icon :value="i" />
              </div>
              <div>
                <div class="name">{{ i }}</div>
                <div class="token-addr" v-if="tokenList[i] && tokenList[i].address">
                  {{ tokenList[i].address.slice(0, 5) + '...' + tokenList[i].address.slice(-4) }}
                  <span class="clipboard-btn" @click="copyText(tokenList[i].address)">
                    <icon value="copy"></icon>
                  </span>
                </div>
              </div>

              <div class="value">{{ $tokenValue(tokens[i]) }}</div>
            </div>
          </section>

          <div class="convert" @click="$router.push('/convert')">
            <icon value="convert" />
            <div class="ml-auto">Convert XBTC</div>
          </div>
        </main>

        <footer class="mt-3">
          <button class="btn btn-block btn-outline-primary" @click="switchWallet">
            Switch Wallet
          </button>
          <a class="btn btn-link btn-block mt-1 logout" @click="logout">
            <icon value="disconnect" />
            Disconnect
          </a>
        </footer>
      </div>
    </div>
  </transition>
  <Wallet v-model:open="openWallet" white></Wallet>
</template>

<script setup>
import { inject, onBeforeUnmount, ref, watch, useTemplateRef } from 'vue'
import { useRoute } from 'vue-router'
import { displayValue, clipboardCopy, parseInputValue } from '../utils.js'
import { disconnect, getBalance, getPublicClient } from '@wagmi/core'
import { getContract } from 'viem'
import erc20Abi from '../abi/erc20.json'

import Wallet from '@exsat/common/components/Wallet.vue'

const openWallet = ref(false)

const { tokenList } = inject('config')
const wagmiConfig = inject('wagmiConfig')
const account = inject('account')
const updateAccount = inject('updateAccount')
const checkConnection = inject('checkConnection')
const toast = inject('toast')

const shown = defineModel('show', {
  type: Boolean
})

const currencies = ['BTC', 'XBTC', 'XSAT'].concat(
  Object.keys(tokenList).filter((t) => t !== 'XSAT' && t !== 'XBTC')
)

const walletRef = useTemplateRef('wallet')
const tokens = ref({})
const route = useRoute()
const modal = inject('modal')

getTokens()

watch(
  route,
  () => {
    shown.value = false
  },
  { deep: true }
)

watch(account, async (newVal) => {
  if (newVal) {
    getTokens()
  }
})

watch(shown, async (newVal) => {
  if (newVal) {
    getTokens()
  }
})

document.addEventListener('click', onDocClick, false)
document.addEventListener('keyup', onEsc, false)

onBeforeUnmount(() => {
  document.removeEventListener('click', onDocClick)
  document.removeEventListener('keyup', onEsc)
})

function onDocClick(e) {
  if (e.target === walletRef.value || e.target.closest('.wallet') === walletRef.value) {
    return
  }
  toggleWallet(false)
}

async function copyText(val) {
  return clipboardCopy(val).then(() => {
    toast.success({ title: 'Address Copied', context: ` <span style="color:black">${val}</span>` })
  })
}

async function clickConnect() {
  // await checkConnection()
  openWallet.value = !openWallet.value
}

async function switchWallet() {
  await disconnect(wagmiConfig)
  await updateAccount('')
  await checkConnection()
}

function toggleWallet(value) {
  shown.value = !shown.value
  if (value === true || value === false) {
    shown.value = value
  }
}

function onEsc(e) {
  if (e.key === 'Escape') {
    toggleWallet(false)
  }
}

async function getTokens() {
  for (const name of currencies) {
    tokens.value[name] = '-'
    if (!account.value) {
      continue
    }
    if (name === 'BTC') {
      getBalance(wagmiConfig, { address: account.value }).then((r) => {
        tokens.value[r.symbol] = displayValue(r.value.toString(), r.decimals)
      })
    } else {
      const tokenInfo = tokenList[name]
      if (tokenInfo && tokenInfo.address) {
        const publicClient = await getPublicClient(wagmiConfig)
        const contract = getContract({
          address: tokenInfo.address,
          abi: erc20Abi,
          client: publicClient
        })

        contract.read.balanceOf([account.value]).then((r) => {
          tokens.value[tokenInfo.symbol] = displayValue(parseInputValue(r, 0), tokenInfo.precision)
        })
      }
    }
  }
}

function logout() {
  if (!confirm('Logout?')) {
    return
  }
  console.log('logout')
  disconnect(wagmiConfig)
  updateAccount('')
  shown.value = false
  localStorage.setItem('walletType', '')
  // window.bitkeep?.ethereum?.disconnect()
  // setTimeout(() => {
  //   window.okxwallet?.disconnect()
  // }, 1000)
  //+3
}
</script>

<style lang="scss" scoped>
.fade-down-enter-active,
.fade-down-leave-active {
  transition: 0.3s;
}

.fade-down-enter-from,
.fade-down-leave-to {
  transform: translateY(-20px);
  opacity: 0;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.wallet {
  position: absolute;
  top: 100%;
  right: 0;
  z-index: 2;
  display: flex;
  flex-direction: row;
  justify-content: end;
  padding: 10px;
  width: 368px;
  color: var(--text-body);

  .wallet-inner {
    border: 1px solid var(--border-gray);
    border-radius: var(--radius-2xl);
    padding: var(--spacing-xl);
    box-shadow: var(--shadow-md);
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: #fff;

    header {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: var(--spacing-2xl);

      .collapse {
        margin-left: auto;
        cursor: pointer;
      }
    }

    main {
      flex: 1;
    }

    footer {
    }
  }

  .well {
    color: inherit;
    padding: var(--spacing-lg);
    background-color: var(--bg-secondary-color);
    border-color: transparent;
    border-radius: var(--radius-md);

    svg {
      margin-bottom: var(--spacing-md);
    }
  }
}

.nav-tabs {
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid #262626;

  .nav-item {
    padding: var(--spacing-md);
    border-bottom: 1px solid var(--orange);
    margin-bottom: -1px;
  }
}

.dimmer {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.5);
}

.convert {
  position: relative;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  padding: var(--spacing-lg);
  background-color: var(--bg-secondary-color);
  border-radius: var(--radius-xl);
  align-items: center;
  font-weight: 600;
  color: var(--orange);
  transition: 0.3s;

  &:hover {
    opacity: 0.75;
  }
}

.tokens {
  margin-bottom: var(--spacing-xl);
  background-color: var(--bg-secondary-color);
  border-radius: var(--radius-xl);
  padding: var(--spacing-lg);
}

.links {
  margin-top: var(--spacing-3xl);
}

.link {
  display: flex;
  flex-direction: row;
  padding: var(--spacing-md) 0;
  align-items: center;

  .icon {
    margin-left: auto;
  }
}

.token {
  display: flex;
  flex-direction: row;
  align-items: center;

  + .token {
    margin-top: 10px;
  }

  .name {
    font-weight: 600;
  }

  .token-icon {
    border-radius: 100px;
    background-color: #fff;
    margin-right: 10px;
    width: 44px;
    height: 44px;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 24px;
      height: 24px;
      object-fit: contain;
    }
  }

  .value {
    margin-left: auto;
  }
}

.wallet-info {
  display: flex;
  flex-direction: row;
  align-items: center;
  float: right;
}

.account {
  .account-hash {
    margin-left: var(--spacing-md);
    vertical-align: middle;
    font-weight: 700;
    cursor: pointer;
    @include sm() {
      display: none;
    }
  }

  .avatar {
    display: inline-block;
    vertical-align: middle;
    width: 2em;
    height: 2em;
    border-radius: 100px;
    margin-left: var(--spacing-sm);
    cursor: pointer;

    svg {
      width: 100%;
      height: 100%;
      display: block;
    }
  }
}

.account-info {
  text-align: center;

  .avatar {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  .account-hash {
    margin: 0;
  }
}

hr {
  border: 0;
  border-top: 1px solid var(--border-gray);
}

.token-addr {
  color: #8c8c8c;
  font-size: var(--font-sm);

  .clipboard-btn {
    cursor: pointer;
  }
}
</style>
